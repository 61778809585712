export const Permissions = {
  manageUsers: 'manage_users',
  manageRoles: 'manage_roles',
  manageTemplates: 'manage_templates',
  manageSettings: 'manage_settings',
  allAdvices: 'all_advices',
  checkAllReports: 'check_all_reports',
  checkOwnReports: 'check_own_reports',
  createAdvice: 'create_advice',
  advicesInApprovalShow: 'advices_in_approval_show',
  advicesInApprovalEdit: 'advices_in_approval_edit',
}

interface AuthRequest {
  email: string,
  password: string
}

interface PasswordRequest {
  currentPassword: string,
  password: string,
  passwordConfirmation: string
}

interface Permission {
  id: number,
  name: string,
  description: string
}

export { AuthRequest, Permission, PasswordRequest }

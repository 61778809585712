import { defineStore } from 'pinia'
import { User } from '@/api/types/user'
import { Permission, Permissions } from '@/api/types/auth'
import { apiAuthLogout, apiAuthMe } from '@/api/auth.api'

export const findPermission = (needle: String, haystack: Permission[] = []) =>
  haystack.some((e: Permission) => needle === e.name)

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authenticating: false,
    user: null as User,
    redirectUri: null,
  }),
  getters: {
    isLoggedIn: state => state.user !== null,
    hasRedirect: state => state.redirectUri !== null,
    canManageUsers: state => !!findPermission(Permissions.manageUsers, state.user?.permissions),
    canManageRoles: state => !!findPermission(Permissions.manageRoles, state.user?.permissions),
    canManageTemplates: state => !!findPermission(Permissions.manageTemplates, state.user?.permissions),
    canManageSettings: state => !!findPermission(Permissions.manageSettings, state.user?.permissions),
    canAllAdvices: state => !!findPermission(Permissions.allAdvices, state.user?.permissions),
    canCheckAllReports: state => !!findPermission(Permissions.checkAllReports, state.user?.permissions),
    canCheckOwnReports: state => !!findPermission(Permissions.checkOwnReports, state.user?.permissions),
    canCreateAdvice: state => !!findPermission(Permissions.createAdvice, state.user?.permissions),
  },
  actions: {
    getUser () {
      return new Promise((resolve, reject) => {
        this.authenticating = true
        apiAuthMe().then((res) => {
          this.user = res.data
          this.authenticating = false
          resolve({})
        }).catch(
          () => {
            this.user = null
            this.authenticating = false
            reject(new Error())
          },
        )
      })
    },
    logout () {
      return new Promise((resolve, reject) => {
        apiAuthLogout().then(() => {
          this.user = null
          // this.authenticated = false

          resolve(true)
        })
          .catch(() => {
            reject(new Error())
          })
      })
    },
    setRedirect (to) {
      this.redirectUri = to
    },
    goToRedirect () {
      this.router.push(this.redirectUri).then(() => {
        this.redirectUri = null
      })
    },
  },
})

import { Permissions } from '@/api/types/auth'
import { EnumRelation } from '@/api/types/person'
import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
    {
        path: '',
        redirect: { name: 'Dashboard' },
    },

    {
        path: '/app',
        component: () => import('@/components/Layout/Layout.vue'),
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: () => import('@/views/Dashboard.vue'),
            },

            {
                path: 'advices',
                component: () => import('@/views/Advices/Base.vue'),
                children: [
                    { path: '', name: 'Advices', component: () => import('@/views/Advices/List.vue') },
                    {
                        path: ':id(\\d+)',
                        component: () => import('@/views/Advices/View.vue'),
                        props: true,
                        children: [
                            { path: '', redirect: 'view' },
                            {
                                path: 'view',
                                name: 'Advices.View',
                                component: () => import('@/views/Advices/View/Overview.vue'),
                            },
                            {
                                path: 'edit',
                                name: 'Advices.Edit',
                                component: () => import('@/views/Advices/View/Edit.vue'),
                                props: true,
                            },
                            {
                                path: 'requester',
                                name: 'Advices.View.Requester',
                                component: () => import('@/views/Advices/View/Requester.vue'),
                            },
                            {
                                path: 'partner',
                                name: 'Advices.View.Partner',
                                component: () => import('@/views/Advices/View/Partner.vue'),
                            },
                            {
                                path: 'partner/add',
                                name: 'Advices.View.Partner.Add',
                                component: () => import('@/views/Advices/View/Person/Add.vue'),
                                props: (route) => ({
                                    relation: EnumRelation.partner,
                                    id: route.params.id,
                                    partnerFor: route.query.partnerFor,
                                }),
                            },
                            {
                                path: 'children',
                                name: 'Advices.View.Children',
                                component: () => import('@/views/Advices/View/Children.vue'),
                            },
                            {
                                path: 'children/add',
                                name: 'Advices.View.Children.Add',
                                component: () => import('@/views/Advices/View/Person/Add.vue'),
                                props: (route) => ({
                                    id: route.params.id,
                                    relation: EnumRelation.child,
                                    parents: route.query.parents,
                                }),
                            },
                            {
                                path: 'grandchildren',
                                name: 'Advices.View.GrandChildren',
                                component: () => import('@/views/Advices/View/GrandChildren.vue'),
                            },
                            {
                                path: 'grandchildren/add',
                                name: 'Advices.View.GrandChildren.Add',
                                component: () => import('@/views/Advices/View/Person/Add.vue'),
                                props: (route) => ({
                                    id: route.params.id,
                                    relation: EnumRelation.grandchild,
                                    parents: route.query.parents,
                                }),
                            },
                            {
                                path: 'parents',
                                name: 'Advices.View.Parents',
                                component: () => import('@/views/Advices/View/Parents.vue'),
                            },
                            {
                                path: 'parents/add',
                                name: 'Advices.View.Parents.Add',
                                component: () => import('@/views/Advices/View/Person/Add.vue'),
                                props: (route) => ({
                                    id: route.params.id,
                                    relation: EnumRelation.parent,
                                    child: route.query.child,
                                }),
                            },
                            {
                                path: 'siblings',
                                name: 'Advices.View.Siblings',
                                component: () => import('@/views/Advices/View/Siblings.vue'),
                            },
                            {
                                path: 'siblings/add',
                                name: 'Advices.View.Siblings.Add',
                                component: () => import('@/views/Advices/View/Person/Add.vue'),
                                props: (route) => ({
                                    id: route.params.id,
                                    relation: EnumRelation.sibling,
                                    parents: route.query.parents,
                                }),
                            },

                            {
                                path: 'persons/:personId/edit',
                                name: 'Advices.View.EditPerson',
                                component: () => import('@/views/Advices/View/Person/Edit.vue'),
                                props: true,
                            },
                            {
                                path: 'assets',
                                name: 'Advices.View.Assets',
                                component: () => import('@/views/Advices/View/Assets.vue'),
                            },
                            {
                                path: 'assets/add',
                                name: 'Advices.View.Assets.Add',
                                component: () => import('@/views/Advices/View/Asset/Add.vue'),
                                props: (route) => ({
                                    id: route.params.id,
                                    personId: route.query.personId,
                                }),
                            },

                            {
                                path: 'assets/:assetId/edit',
                                name: 'Advices.View.EditAsset',
                                component: () => import('@/views/Advices/View/Asset/Edit.vue'),
                                props: true,
                            },

                            {
                                path: 'familytree',
                                name: 'Advices.View.FamilyTree',
                                component: () => import('@/views/Advices/View/FamilyTree.vue'),
                            },
                            // {
                            //   path: 'familytree2',
                            //   name: 'Advices.View.FamilyTreeTwo',
                            //   component: () => import('@/views/Advices/View/FamilyTree.vue'),
                            // },
                            {
                                path: 'parts',
                                name: 'Advices.View.Parts',
                                component: () => import('@/views/Advices/View/Parts.vue'),
                            },
                            {
                                path: 'wishes',
                                name: 'Advices.View.Wishes',
                                component: () => import('@/views/Advices/View/Wishes.vue'),
                            },

                            {
                                path: 'wishes/edit',
                                name: 'Advices.View.Wishes.Edit',
                                component: () => import('@/views/Advices/View/Wishes/Edit.vue'),
                            },

                            {
                                path: 'reports',
                                name: 'Advices.View.Reports',
                                component: () => import('@/views/Advices/View/Reports.vue'),
                            },

                            {
                                path: 'reports/:reportId',
                                name: 'Advices.View.Reports.View',
                                props: true,
                                component: () => import('@/views/Advices/View/Report/View.vue'),
                            },

                        ],
                    },
                    {
                        path: 'reports',
                        name: 'Advice.Reports',
                        component: () => import('@/views/Advices/Reports/List.vue'),

                    },
                ],
            },

            {
                path: 'system',
                name: 'System',
                component: () => import('@/views/System/Base.vue'),
                children: [
                    {
                        path: 'users',
                        name: 'System.Users',
                        component: () => import('@/views/System/Users.vue'),
                        meta: { requiresAllPermissions: [Permissions.manageUsers] },
                    },
                    {
                        path: 'users/new',
                        name: 'System.Users.New',
                        component: () => import('@/views/System/Users/New.vue'),
                        meta: { requiresAllPermissions: [Permissions.manageUsers] },
                    },
                    {
                        path: 'users/:id',
                        component: () => import('@/components/Core/Child.vue'),
                        meta: { requiresAllPermissions: [Permissions.manageUsers] },
                        children: [
                            {
                                path: '',
                                name: 'System.Users.View',
                                component: () => import('@/views/System/Users/View.vue'),
                                props: true,
                            },
                            {
                                path: 'edit',
                                name: 'System.Users.Edit',
                                component: () => import('@/views/System/Users/Edit.vue'),
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'roles',
                        name: 'System.Roles',
                        component: () => import('@/views/System/Roles.vue'),
                        meta: { requiresAllPermissions: [Permissions.manageRoles] },
                    },
                    {
                        path: 'roles/new',
                        name: 'System.Roles.New',
                        component: () => import('@/views/System/Roles/New.vue'),
                        meta: { requiresAllPermissions: [Permissions.manageRoles] },
                    },
                    {
                        path: 'roles/:id',
                        component: () => import('@/components/Core/Child.vue'),
                        meta: { requiresAllPermissions: [Permissions.manageRoles] },
                        children: [
                            {
                                path: '',
                                name: 'System.Roles.View',
                                component: () => import('@/views/System/Roles/View.vue'),
                                props: true,
                            },
                            {
                                path: 'edit',
                                name: 'System.Roles.Edit',
                                component: () => import('@/views/System/Roles/Edit.vue'),
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'settings',
                        name: 'System.Settings',
                        component: () => import('@/views/System/Settings.vue'),
                        meta: {
                            requiresAllPermissions: [Permissions.manageSettings],
                        },
                    },
                    {
                        path: 'templates',
                        name: 'System.ReportTemplates',
                        component: () => import('@/views/System/ReportTemplates.vue'),
                        meta: { requiresAllPermissions: [Permissions.manageTemplates] },

                    },
                    {
                        path: 'templates/:id',
                        component: () => import('@/components/Core/Child.vue'),
                        meta: { requiresAllPermissions: [Permissions.manageTemplates] },
                        children: [
                            {
                                path: '',
                                name: 'System.ReportTemplates.View',
                                component: () => import('@/views/System/ReportTemplates/View.vue'),
                                props: true,
                            },
                            {
                                path: ':versionId/edit',
                                name: 'System.ReportTemplates.Edit',
                                component: () => import('@/views/System/ReportTemplates/Edit.vue'),
                                props: true,
                            },
                            {
                                path: ':versionId/test/:adviceId',
                                name: 'System.ReportTemplates.Test',
                                component: () => import('@/views/System/ReportTemplates/Test.vue'),
                                props: true,
                            },

                        ],
                    },
                ],
                meta: {
                    requiresAnyPermissions: [Permissions.manageUsers, Permissions.manageRoles, Permissions.manageSettings, Permissions.manageTemplates],
                },
            },
            {
                path: 'profile',
                component: () => import('@/views/Profile/Home.vue'),
                children: [
                    { path: '', name: 'profile', redirect: { name: 'profile.account' } },
                    {
                        path: 'account',
                        name: 'profile.account',
                        component: () => import('@/views/Profile/Account.vue'),
                    },
                    {
                        path: 'password',
                        name: 'profile.password',
                        component: () => import('@/views/Profile/Password.vue'),
                    },
                    {
                        path: '2facodes',
                        name: 'profile.2facodes',
                        component: () => import('@/views/Profile/2FACodes.vue'),
                    },
                ],
            },
        ],
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Auth/Login.vue'),
    },
    {
        path: '/reset-password',
        name: 'reset_password',
        props: route => ({ token: route.query.token }),
        component: () => import('@/views/Auth/ResetPassword.vue'),
    },
    {
        path: '/login/two_factor',
        name: 'two_factor',
        component: () => import('@/views/Auth/TwoFactor.vue'),
    },

]
export default routes
